/* common-title */
.title {
    padding: 13px 93px 9px 27px;
    border-bottom: 1px solid #8c8c8c;
    color: #2d2d2d;
    font-size: 15px;
    line-height: 1.25;
    word-wrap: break-word;

    &:before {
        content: '';
        position: absolute;
        margin: 5px 0 0 -17px;
        width: 12px;
        height: 9px;
        background: url("/MW/img/common/r1_bul_common_v1.png") no-repeat 0 -25px;
    }
}
.title2 {
    padding: 13px 27px 9px 27px;
    color: #2d2d2d;
    font-size: 15px;
    line-height: 1.25;
    word-wrap: break-word;

    &:before {
        content: '';
        position: absolute;
        margin: 5px 0 0 -17px;
        width: 12px;
        height: 9px;
        background: url("/MW/img/common/r1_bul_common_v1.png") no-repeat 0 -25px;
    }

    .s {
        color: #888;
        font-size: 11px;
        font-weight: normal;
    }
}

.tit_bs {
    margin: 19px 10px 9px;
    padding-left: 8px;
    color: #3b3b3b;
    font-size: 14px;
    line-height: 1.2;

    &:before {
        content: '';
        position: absolute;
        margin: 3px 0 0 -8px;
        width: 0;
        height: 0;
        border-left: 4px solid #4178cd;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
    }

    .st {
        font-size: 12px;
        font-weight: normal;
    }

    .title + & {
        margin-top: 10px;
    }

    .s {
        color: #666;
        font-size: 12px;
        font-weight: normal;
    }
}

.stit_bs {
    margin: 9px 0 9px;
    padding-left: 8px;
    color: #555;
    font-size: 14px;
    line-height: 1.2;

    &:before {
        content: '';
        position: absolute;
        margin: 6px 0 0 -8px;
        width: 3px;
        height: 3px;
        background: #a4a5a0;
    }

    #cts > & {
        margin-left: 10px;
    }
}


.tit_note {
    margin-top: 13px;
    padding-left: 19px;
    color: #7a5d3d;
    font-size: 14px;
    line-height: 1.2;

    &:before {
        content: '';
        position: absolute;
        margin: 1px 0 0 -19px;
        width: 15px;
        height: 14px;
        background: url("/MW/img/common/r1_bul_common_v1.png") no-repeat 0 -35px;
    }

    #cts > & {
        margin-left: 10px;
    }
}

.tbtn {
    position: absolute;
    top: 8px;
    right: 10px;
}
/* common-sub title */
.title_sb {
    overflow: hidden;
    height: 44px;
    background-color: #fff;
    color: #111;
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    line-height: 44px;
    border-bottom: 1px solid #f4f4f4;

    body.grayType &{
        border-color: #d1d1d6;
    }
}
.btn_rtop_sb {
    position: absolute;
    right: 0;
    top: 0;

    a {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        height: 37px;
        padding: 0 10px;
        background: none;
        border: none;
        color: #6ad3ff;
        font-size: 13px;
        line-height: 38px;
        white-space: nowrap;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
        -webkit-perspective: 100px;

        &:after {
            content: '';
            display: inline-block;
            width: 6px;
            height: 6px;
            margin-left: 5px;
            border: 2px solid #6ad3ff;
            border-width: 2px 2px 0 0;
            vertical-align: 0;
            transform: rotate(45deg);
        }
    }
}
