/* 전면팝업 */
.cp_allpopup {
    display: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    a {
        display: inline;
    }

    .cp_wrap {
        overflow: auto;
        position: relative;
        height: 100%;
        background: #fff;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        z-index: 1;
    }
    html.android.v2_0 & {
        overflow: visible;

        .cp_wrap {
            overflow: visible;
            height: auto;
            min-height: 100%;
        }

        .close {
            position: absolute;
        }
    }

    .close {
        overflow: hidden;
        position: fixed;
        top: 10px;
        right: 10px;
        width: 32px;
        height: 32px;
        background: rgba(0, 0, 0, .7) url("/MW/img/common/campaign_allpopup_r2.png") no-repeat 7px 8px;
        background-size: 15px 15px;
        border: 1px solid #222;
        border-radius: 2px;
        text-indent: -1000px;
        z-index: 2;
    }

    &.start .cp_wrap {
        transform: translateY(100%);
    }

    &.animation .cp_wrap {
        transform: translateY(0%);
        transition: transform .5s ease;
    }

    &.end .cp_wrap {
        transform: translateY(100%);
        transition: transform .5s ease;
    }

    &.start .close, &.animation .close, &.end .close {
        transform: scale(0);
    }
}

body.allpopup {
    position: relative;
    height: 100%;

    #wrap {
        overflow: hidden;
        position: relative;
        height: 100%;
    }

    .cp_allpopup {
        display: block;
        overflow: hidden;
        height: 100%;
    }
}

/* [프로모션내]회원DB에 CI없는 고객에 대한 팝업 안내 */
.pop_eguide {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    z-index: 99;
    box-sizing: border-box;

    html.android.v4_0 & {
        -webkit-transform: translateZ(0);
    }

    .wrp {
        max-width: 316px;
        margin: 0 auto;
        border: 2px solid #e01c22;
        background: #fff;
    }

    .txt {
        margin: 15px 15px 0;
        padding: 13px 15px;
        background: #f1f1f1;
        font-size: 14px;
        line-height: 19px;

        strong {
            color: #111;
        }
    }

    .txs {
        margin: 10px 0 15px;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
    }

    .btm {
        overflow: hidden;
        margin: 0 6px;
        padding: 6px 4px;
        border-top: 1px solid #dfdfdf;

        .chkBox {
            float: left;
            font-size: 13px;
            line-height: 24px;

            label:before {
                vertical-align: -7px;
            }
        }

        button {
            float: right;
            position: relative;
            padding: 1px 10px 0;
            background: none;
            border: none;
            color: #666;
            font-size: 13px;
            line-height: 24px;
        }
    }
}
