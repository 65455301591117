body.typeCouponDown {
    background: $color-gray-13 !important;

    > *:not(.pop_coupon_down):not(.r-coupon-down):not(#NetFunnel_Loading_Popup):not(#mpopup_bg):not(#pop_iframe):not(.dim):not(.pop_plus_coupon):not(.r-plus-coupon):not(.toast_coupon) {
        display: none !important;
    }
    .dim {
        z-index: 105;
    }
    .pop_plus_coupon,
    .r_plus_coupon,
    .toast_coupon {
        z-index: 110;
    }
}
