/* common-paging 신규 */
//v_common.css는 디자인 그대로 유지(이 디자인과 상이)
.pages {
    position: relative;
    height: 32px;
    padding: 8px 0 0;
    text-align: center;
    white-space: nowrap;

    & > * {
        display: inline-block;
        height: 32px;
        padding: 0 12px;
        color: #666;
        font-size: 15px;
        line-height: 32px;
        vertical-align: top;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 1px;
    }

    strong, a.on {
        color: #f43142;
        font-weight: normal;
    }

    %page-prev-next {
        position: relative;
        width: 32px;
        border: 1px solid #c7c7c7;
        color: transparent;
        font-size: 5px;
        background-color: #fff;
        background-image: linear-gradient(rgba(17,17,17,0), rgba(17,17,17,.04));

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            margin: -6px 0 0 -6px;
            border: 1px solid #333;
            transform: rotate(45deg);
            content: '';
        }
    }

    .prev {
        @extend %page-prev-next;
        margin-right: 4px;

        &:before {
            border-width: 0 0 1px 1px;
            margin-left: -2px;
        }
    }
    .next {
        @extend %page-prev-next;
        margin-left: 4px;

        &:before {
            border-width: 1px 1px 0 0;
            margin-left: -9px;
        }
    }

    span.prev,
    span.next {
        opacity: .5;
    }

    .prev,
    .next {
        &.disable{
            background: #f4f4f4;
            border-color: rgba(204,204,204,0.4);
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            &:before{
                border-color: #ccc;
            }
        }
    }

}
