.f_top {
    position: fixed;
    overflow: hidden;
    bottom: 20px;
    right: 10px;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.9) url("/MW/img/common/top2.png") 10px 6px no-repeat;
    background-size: 20px 30px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16);
    color: transparent;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    line-height: 8px;
    z-index: 99;
}

.header-relative,
.header-fixed {
    .c-gotop,
    .f_top {
        @include size(40px 40px);

        position: fixed;
        overflow: hidden;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        bottom: 20px;
        right: 10px;
        background: url("/MW/img/common/top2.png") 0 0 no-repeat;
        background-size: 40px 40px;
        color: transparent;
        z-index: 99;
    }
}
.header-relative {
    .c-gotop {
        bottom: 72px;
    }
}

// 기타 케이스
.c-footer {
    .f_top {
        @include size(40px 40px);
        position: fixed;
        overflow: hidden;
        border-radius: 0;
        box-shadow: none;
        bottom: 20px;
        right: 10px;
        background: url("/MW/img/common/top2.png") 0 0 no-repeat;
        background-size: 40px 40px;
        color: transparent;
        z-index: 99;
    }
}
