/* 좋아요 레이어 팝업 */
.pop_like {
    display: none;
    position: absolute;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    padding: 10px 0;
    background: #fff;
    z-index: 102;

    .txt {
        margin: 14px 0 24px;
        color: #3b3e46;
        font-size: 1em;
        font-weight: bold;
        text-align: center;
        line-height: 22px;

        .like {
            color: #f30904;

            &:after {
                display: inline-block;
                position: relative;
                top: -1px;
                width: 14px;
                height: 14px;
                margin: 0 2px;
                background: url("/MW/img/corner/ico_like_new.png") no-repeat -32px 0;
                background-size: 65px 15px;
                vertical-align: middle;
                content: "";
            }
        }
    }

    .btn_w {
        margin: 0;
        padding: 10px 15px 0;
        border-top: 1px solid #eee;

        > div {
            &:first-child {
                -webkit-box-flex: 0.5;
                -webkit-flex: 2;
                flex: 2;
            }

            .bbtn {
                font-size: 14px;
                font-weight: normal;
                line-height: 38px;
            }
        }
    }
}

/* layer popup style */
%pop-close {
    .close {
        position: absolute;
        overflow: hidden;
        top: 0;
        right: 0;
        width: 42px;
        height: 50px;
        padding: 0;
        border: none;
        background: none;
        color: transparent;
        text-indent: -100px;
        -webkit-transform: translateZ(0);

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 1px;
            height: 19px;
            margin: -10px 0 0 -1px;
            background: #666;
            transform: rotate(45deg);
        }
        &:after {
            width: 19px;
            height: 1px;
            margin: -1px 0 0 -10px;
        }
    }
}

.pop_atype {
    display: none;
    overflow: hidden;
    position: absolute;
    left: 50%;
    width: 296px;
    margin-left: -148px;
    border-radius: 8px;
    background: #fff;
    z-index: 102;
    box-shadow: 14px 18px 50px rgba(0, 0, 0, 0.3), 0 5px 10px rgba(0, 0, 0, 0.15);

    html.android.v4_0 & {
        -webkit-transform: translateZ(0);
    }

    .btn_w {
        margin: 0 0 10px;
        padding: 10px 15px 0;
        border-top: 1px solid #eee;

        .bbtn {
            font-size: 14px;
            line-height: 38px;
        }
    }
    @extend %pop-close;
}

.pop_btype {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 296px;
    border-radius: 8px;
    background: #fff;
    z-index: 102;
    box-shadow: 14px 18px 50px rgba(0, 0, 0, 0.3), 0 5px 10px rgba(0, 0, 0, 0.15);

    @extend %pop-close;
}

/* layer popup - title/btn */
.pops_tit {
    padding: 14px 40px 14px 16px;
    border-bottom: 1px solid #eee;
    color: #333;
    font-size: 17px;
    line-height: 1.38;
    text-align: left;
}

.pops_btn {
    display: flex;
    overflow: hidden;
    border-top: 1px solid #f4f4f4;

    .pbtn {
        display: block;
        overflow: hidden;
        width: 100%;
        height: 48px;
        background: transparent;
        border: none;
        border-left: 1px solid #fafafa;
        color: #111;
        font-size: 16px;
        line-height: 48px;
        text-align: center;
        flex: 1;
        box-sizing: border-box;

        &.frd {
            color: #f43142;
        }

        &:first-child {
            border-left: none;
        }
    }
}

.pops_cont {
    margin: 15px 0;
}

/* page popup head */
.pop_title {
    position: relative;
    height: 44px;
    border-bottom: 1px solid #d1d1d6;
    background: #fff;

    h1 {
        overflow: hidden;
        margin: 0 55px;
        color: #111;
        font-size: 18px;
        font-weight: normal;
        text-align: center;
        line-height: 44px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 52px;
        height: 44px;
        border: none;
        background: none;
        color: transparent;
        font-size: 10px;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1px;
            height: 25px;
            margin-top: -13px;
            background: #333;
            content: "";
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }

    .prev {
        position: absolute;
        top: 0;
        left: 0;
        width: 52px;
        height: 44px;
        border: none;
        background: none;
        color: transparent;
        font-size: 10px;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            content: "";
        }

        &:before {
            width: 21px;
            height: 1px;
            margin-left: -10px;
            background: #333;
        }
        &:after {
            width: 14px;
            height: 14px;
            margin: -7px 0 0 -7px;
            border: 1px solid #333;
            border-width: 0 0 1px 1px;
            transform: rotate(45deg);
        }
    }
}

.pop_title_wrp {
    height: 45px;

    .pop_title {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
    }
}

/* 제휴채널 앱호출 */
.pop_ext_app {
    position: fixed;
    left: 50%;
    width: 340px;
    margin-left: -170px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    z-index: 102;

    .con {
        padding: 15px;
        border: 1px solid #afb0c2;
        background: #fff;
    }

    .bnr {
        overflow: hidden;

        a {
            display: block;
            max-width: 308px;
            max-height: 73px;
            margin: auto;
        }

        img {
            display: block;
            width: 308px;
            height: 73px;
        }
    }

    .btn_app {
        display: block;
        margin-top: 10px;
        background: #fff;
        border: 1px solid #ec565a;
        border-radius: 2px;
        color: #eb413b;
        font-size: 14px;
        line-height: 40px;
        text-align: center;
    }

    .txt_def {
        height: 69px;
        padding: 4px 0 0 80px;
        margin: 0 10px;
        background: url("/MW/img/common/ext_applink.png") no-repeat 0 2px;
        background-size: 65px 65px;
        color: #333;
        font-size: 14px;
        line-height: 19px;

        b {
            display: block;
            color: #1a1a1a;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .close {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        width: 45px;
        height: 45px;
        background: none;
        border: none;
        text-indent: -200px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            background: #5d5fd6;
            transform: rotate(45deg);
        }
        &:before {
            width: 1px;
            height: 23px;
            margin: -13px 0 0 -1px;
        }
        &:after {
            width: 23px;
            height: 1px;
            margin: -1px 0 0 -12px;
        }
    }
}

@media screen and (max-width: 359px) {
    .pop_ext_app {
        width: 300px;
        margin-left: -150px;
    }
    .pop_ext_app .con {
        padding: 15px 10px;
    }
    .pop_ext_app .bnr img {
        width: 278px;
        height: 66px;
    }
    .pop_ext_app .txt_def {
        margin: 0 5px;
    }
}
