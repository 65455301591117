html {
    height: 100%;
}

body {
    background: #fff;
    color: #666;
    font-size: 16px;
    letter-spacing: -1px;
    overflow-wrap: break-word;
    -webkit-text-size-adjust: none;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, select, textarea, p, blockquote, th, td, button {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Droid Sans", "Helvetica", "Apple-Gothic", "Tahoma", dotum, gulim, sans-serif;
}

main, section, nav, article, aside, header, footer, figure, figcaption {
    display: block;
}

form, fieldset, img {
    border: 0 none;
}

table {
    border-spacing: 0;
    border: 0;
}

em {
    font-style: normal;
}

ul, ol, li {
    list-style: none outside;
}

input, select {
    vertical-align: middle;
}

a {
    text-decoration: none;
    color: inherit;
}

legend, caption, .skip, .hide {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    opacity: 0;
}

input[type='text'], input[type='password'], input[type='tel'], input[type='number'], input[type='search'], input[type='email'] {
    height: 32px;
    padding: 0 8px;
    background: #fff;
    color: #333;
    border: 1px solid #ccc;
    font-size: 14px;
    border-radius: 0;
    appearance: none;
    box-sizing: border-box;
}

input[type=search]::-webkit-search-cancel-button,
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    appearance: none;
}

button, [type='button'], [type='submit'], [type='reset'] {
    cursor: pointer;
    border-radius: 0;
    appearance: none;
    outline: none;
}

select {
    height: 32px;
    padding: 0 30px 0 3px;
    border: 1px solid #ccc;
    background: #fff url("/MW/img/order/img_form.png") no-repeat 100% 0;
    background-size: 31px 131px;
    color: #666;
    font-size: 13px;
    border-radius: 0;
    appearance: none;
}

textarea {
    padding: 8px;
    border: 1px solid #ccc;
    color: #333;
    font-size: 14px;
    border-radius: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

iframe {
    display: block;
}

[hidden] {
    display: none;
}

html.android.v2_0 a, html.android.v2_0 button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    width: 1px;
    height: 1px;
    font-size: 6px;
    line-height: 1;
    white-space: nowrap;
}
#wrap {
    max-width: 1280px;
    margin: auto;
}
