@charset "UTF-8";

@import "../sprites/sp_coupon_down";

//쿠폰 다운 팝업
.pop_coupon_down {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: $color-gray-13;
    z-index: 105;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    .bottom {
        display: flex;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 56px;
        background: rgba(249, 249, 249, 0.95);
        z-index: 106;
        box-sizing: border-box;

        button {
            display: block;
            height: 56px;
            border: none;
            color: #fff;
            font-size: 17px;
        }

        .btn_cancel {
            width: 100px;
            background: #b4373a;

            &:last-of-type {
                background: $color-11st-red;
                flex: 1;
            }
        }

        .btn_down_all {
            width: 100%;
            background: $color-11st-red;
            flex: 1;

            &:disabled {
                background: #ccc;

                &:hover {
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                }
            }
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 52px;
        height: 52px;
        border: none;
        background: none;
        color: transparent;
        font-size: 10px;
        z-index: 20;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1px;
            height: 25px;
            margin-top: -13px;
            background: $color-gray-03;
            content: "";
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }

    //개편후 정리필요
    .ttit ~ .sum_clc {
        margin: 0 0 24px;
        padding: 16px 18px 16px;
    }

    .sum_clc {
        margin: 0 16px 24px;
        padding: 16px 2px 20px;
        background: #fff;

        ul {
            display: table;
            width: 100%;
        }

        li {
            display: table-cell;
            position: relative;
            width: 33.33%;
            padding: 0 4px;
            color: $color-gray-03;
            text-align: center;
            white-space: nowrap;
            vertical-align: top;

            > div {
                display: inline-block;
                text-align: right;
            }

            em {
                display: block;
                height: 16px;
                padding-right: 1px;
                font-size: 11px;
                line-height: 16px;
            }

            .prc {
                display: block;
                margin-top: 4px;
                font-size: 17px;
                line-height: 20px;
                white-space: nowrap;
            }

            .oprc {
                display: block;
                color: $color-gray-07;
                font-size: 13px;
                line-height: 16px;
                text-decoration: line-through;
            }

            .sprc {
                display: block;
                margin-left: -40px;
                color: $color-11st-red;
                font-size: 13px;
                line-height: 16px;
            }

            &:nth-child(2) {
                color: $color-11st-red;

                &:before {
                    content: "";
                    position: absolute;
                    top: 30px;
                    left: -4px;
                    width: 8px;
                    height: 1px;
                    background: $color-gray-03;
                }

                .prc {
                    font-weight: bold;
                }
            }

            &:nth-child(3) {
                &:before {
                    content: "";
                    position: absolute;
                    top: 28px;
                    left: -4px;
                    width: 8px;
                    height: 2px;
                    border: solid $color-gray-03;
                    border-width: 1px 0;
                }

                .prc {
                    color: $color-gray-02;
                    font-weight: bold;
                }
            }
        }

        &.sum_clc_fix {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            margin: 0;
            padding: 16px 16px 12px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
            z-index: 106;
            box-sizing: border-box;
        }
    }
}

.coupon_down_cont {
    overflow: hidden;
    position: relative;
    background: $color-gray-13;
}

.prd_coupon_down {
    overflow: hidden;
    padding-bottom: 72px;

    .ttit {
        padding: 23px 0;
        background: #fff;
        color: $color-gray-02;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        text-align: center;
    }

    .ttxt {
        margin: 30px 0 16px;
        color: $color-gray-02;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        text-align: center;
    }

    .stit {
        margin: 24px 16px 12px;
        color: $color-gray-03;
        font-size: 14px;
        font-weight: normal;
        line-height: 17px;
    }

    .stit_hide {
        overflow: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
        white-space: nowrap;
        text-indent: -5px;
    }

    .cdown_lst {
        margin: 12px 16px 0;

        li {
            margin-top: 4px;
        }
    }

    .cdown {
        position: relative;
        padding: 17px 56px 13px 17px;
        border-radius: 4px;
        background: #fff;
        color: $color-gray-03;
        font-size: 13px;
        line-height: 20px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid $color-gray-11;
            border-radius: 4px;
        }

        .prc {
            float: left;
            width: 78px;
            color: $color-11st-red;
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
        }

        .icon {
            display: inline-block;
            padding: 0 8px;
            margin: 0 0 6px 0;
            border: 1px solid $color-gray-03;
            border-radius: 17px;
            color: $color-gray-03;
            font-size: 12px;
            font-weight: bold;
            line-height: 18px;
            vertical-align: top;

            &.icon_cart {
                border-color: $color-11st-red;
                color: $color-11st-red;
            }

            &.icon_delivery {
                border-color: #0b83e6;
                color: #0b83e6;
            }
        }

        > div {
            overflow: hidden;
        }

        .pay,
        .name,
        .cont,
        .terms {
            display: block;
        }

        %cdown-btn {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 48px;
            border-radius: 0 4px 4px 0;
        }

        .down {
            @extend %cdown-btn;
            width: 49px;
            background: linear-gradient($color-11st-red 49%, #fff 50%) repeat-y 0 0;
            background-size: 1px 4px;
            background-color: $color-11st-red;
            border: none;
            color: #fff;
            font-size: 13px;

            span {
                //@include sprite-retina($sp_coupon_down_ic_down);
                display: block;
                overflow: hidden;
                width: 24px;
                height: 24px;
                margin: 1px auto;
                color: transparent;
                font-size: 10px;
                background: url("/MW/img/order/ico_coupon_down.png") no-repeat 0 0;
                background-size: 100% 100%;
            }
        }

        .end {
            @extend %cdown-btn;
            background: linear-gradient(rgba(238, 238, 238, 1) 49%, rgba(238, 238, 238, 0) 50%) repeat-y 0 0;
            background-size: 1px 4px;
            color: $color-gray-03;
            font-size: 13px;
            line-height: 15px;
            font-weight: bold;
            text-align: center;

            span {
                display: block;
                position: absolute;
                top: 50%;
                width: 100%;
                transform: translateY(-50%);
            }
        }

        .apply {
            @extend %cdown-btn;
            background: linear-gradient(rgba($color-11st-red, 1) 49%, rgba($color-11st-red, 0) 50%) repeat-y 0 0;
            background-size: 1px 4px;
            color: $color-11st-red;
            font-size: 13px;
            font-weight: bold;
            line-height: 19px;
            text-align: center;

            span {
                display: block;
                position: absolute;
                top: 50%;
                width: 100%;
                transform: translateY(-50%);

                //&:before{
                //    @include sprite-retina($sp_coupon_down_ic_after);
                //    content: '';
                //    display: block;
                //    margin: 0 auto 5px;
                //}
            }
        }

        &.cdelivery {
            .prc {
                color: #0b83e6;
            }
        }

        &.capply {
            &:before {
                border-color: $color-11st-red;
            }

            .icon {
                border-color: $color-11st-red;
                color: $color-11st-red;
            }

            > div,
            .prc {
                color: $color-11st-red;
            }
        }
    }

    .cdown_non {
        margin: 100px 0;

        p {
            color: $color-gray-02;
            font-size: 16px;
            line-height: 24px;
            text-align: center;

            &:before {
                content: "";
                display: block;
                width: 46px;
                height: 46px;
                margin: 0 auto 20px;
                border: 1px solid $color-gray-10;
                border-radius: 50%;
                background: linear-gradient($color-gray-10, $color-gray-10) no-repeat 50% 11px, linear-gradient($color-gray-10, $color-gray-10) no-repeat 50% 32px;
                background-size: 3px 18px, 3px 3px;
                //background: url("/MW/img/order/img_cart_non.png") no-repeat;
                //background-size: 100% 100%;
            }
        }
    }
}

.pop_plus_coupon {
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);

    .btn_w {
        margin: 0 16px 16px;

        .bbtn {
            letter-spacing: 0;
            border-radius: 4px;

            &:before {
                display: inline-block;
                width: 24px;
                height: 24px;
                margin: -1px 6px 1px 0;
                background: url("/MW/img/order/ico_store_zzim.png") no-repeat;
                background-size: 100% 100%;
                vertical-align: middle;
                content: "";
            }
        }
        .talk:before {
            background-image: url("/MW/img/order/ico_talk.png");
        }
        .ico_empty:before {
            display: none;
        }
    }
    &.pop_plus_coupon_app_v2 {
        position: relative;
        top: 0 !important;
        left: 0;
        transform: none;

        .close {
            position: absolute;
            overflow: hidden;
            top: 0;
            right: 0;
            width: 42px;
            height: 50px;
            padding: 0;
            border: none;
            background: none;
            color: transparent;
            text-indent: -100px;
            transform: translateZ(0);

            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 1px;
                height: 19px;
                margin: -10px 0 0 -1px;
                background: $color-gray-04;
                transform: rotate(45deg);
            }
            &:after {
                width: 19px;
                height: 1px;
                margin: -1px 0 0 -10px;
            }
        }
    }
}
.cont_plus_coupon {
    padding: 32px 16px 24px;
    letter-spacing: 0;

    .tit {
        font-size: 15px;
        color: $color-gray-02;
        line-height: 22px;
        text-align: center;

        .point {
            color: $color-11st-red;
        }
    }
    .txt {
        margin-top: 16px;
        font-size: 14px;
        color: $color-gray-04;
        line-height: 20px;
        text-align: center;
    }
    .guide {
        margin-top: 12px;
        font-size: 12px;
        color: $color-gray-06;
        line-height: 18px;
        text-align: center;
    }
    .coupon_info_box {
        overflow: hidden;
        margin: 16px 0 -8px;

        & + .coupon_info_box {
            margin-top: 16px;
        }
    }
    .coupon_info {
        position: relative;
        padding: 12px 0 12px 92px;
        border: 1px solid $color-gray-10;
        font-size: 13px;

        &:before,
        &:after {
            position: absolute;
            top: -5px;
            left: 72px;
            z-index: 1;
            width: 9px;
            height: 9px;
            border: 1px solid $color-gray-10;
            border-radius: 100%;
            background: #fff;
            content: "";
        }
        &:after {
            top: auto;
            bottom: -5px;
        }
        .percent,
        .discount {
            display: -webkit-box;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 77px;
            border-right: 1px dashed $color-gray-10;
            font-size: 17px;
            color: $color-11st-red;
            text-align: center;
            -webkit-box-orient: vertical;
            -webkit-box-pack: center;
        }
        .discount {
            font-size: 13px;
            font-weight: normal;

            span {
                font-size: 17px;
                font-weight: bold;
            }
        }
        .type {
            display: block;
            margin-bottom: 4px;
            font-weight: bold;
            color: $color-gray-02;
        }
        .info_txt {
            color: $color-gray-07;
        }
    }
    // PDP-553 삭제예정 시작
    .btn_w {
        margin: 24px 0 0;
    }
    // PDP-553 삭제예정 끝
}
