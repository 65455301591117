/* common-list */
.bs_lst {
    margin-top: 8px;

    li {
        position: relative;
        padding-left: 8px;
        margin-top: 4px;
        color: #666;
        font-size: 12px;
        line-height: 1.25;

        &:before {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            width: 3px;
            height: 3px;
            background: #a4a5a0;
        }

        strong {
            color: #111;
        }
    }

    section &:first-child {
        margin-top: 13px;
    }
}

/* common class */
.msgbox {
    margin: 8px 8px 0;
    padding: 18px 10px 18px;
    border: 1px solid #ababab;
    text-align: center;

    > strong {
        color: #2d2d2d;
        font-size: 14px;
    }

    p {
        margin-top: 5px;
        color: #666;
        font-size: 12px;
        line-height: 1.25;
    }
}
/* html구좌(전면) */
.db_html_all {
    overflow: hidden;
    width: 100%;

    img {
        max-width: 100%;
    }
}
/* html구좌(영업이벤트) */
table.eventlayout {
    border-collapse: collapse;
    width: 100%;

    td {
        padding: 0;

        a {
            display: block;
        }

        img {
            display: block;
            width: 100%;
        }
    }
}

.html_set {
    position: relative;
    text-align: left;

    a {
        display: inline;
    }

    .w_cell img {
        display: block;
        width: 100%;
    }

    .w_d {
        display: table;
        width: 100%;

        .cell {
            display: table-cell;
            vertical-align: top;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    .pop {
        display: none;
        position: absolute;

        & > a img, & > img {
            display: block;
            width: 100%;
        }
    }
}
/* 상세보기 */
#cTop {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 100;
}
