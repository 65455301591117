.c-webtoapp {
    position: relative;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px 6px;
    box-sizing: border-box;
    background-color: $color-gray-14;

    &__wrap {
        min-width: 300px;
        background: $color-11st-gradient;
        border-radius: 8px;
        box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.12) inset, 0 2px 4px 0 rgba(255, 1, 70, 0.24);
    }
    &__link {
        display: flex;
        align-items: center;
        height: 52px;
        padding: 10px 42px 10px 8px;
        box-sizing: border-box;
    }
    &__desc {
        display: flex;
        align-items: center;
        margin-right: auto;
        color: $color-gray-14;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        word-break: keep-all;

        &:before {
            content: "";
            flex-shrink: 0;
            display: block;
            width: 44px;
            height: 44px;
            margin-right: 4px;
            background: url("/MW/img/common/app_tiny.png") no-repeat 0 0;
            background-size: 100% auto;
        }
    }
    &__button {
        flex-shrink: 0;
        overflow: hidden;
        height: 27px;
        margin-left: 8px;
        padding: 0 8px;
        border: 0;
        color: $color-11st-red;
        font-size: 14px;
        font-weight: 700;
        line-height: 29px;
        background-color: $color-gray-14;
        border-radius: 4px;
    }
    &__point {
        color: $color-11st-red;
        background: linear-gradient(135deg, $color-11st-gradient-orange, $color-11st-red 52%, $color-11st-gradient-pink);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    &__close {
        position: absolute;
        top: 50%;
        right: 16px;
        margin-top: -3px;
        padding: 12px;
        border: 0;
        background-color: transparent;
        transform: translateY(-50%);

        svg {
            display: block;
        }
    }
    // &__close-icon {
    //     @include get-sprite-common("icon_close_white", 12);

    //     overflow: hidden;
    //     display: block;
    //     color: transparent;
    // }

    // fixed 고정이 필요한 경우
    &--fixed {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 40;
    }    
}

.header-fixed {
    &.header-fixed--appbanner {
        padding-top: 115px;

        .c-webtoapp {
            position: fixed;
            top: 0;
            width: 100%;
            max-width: 1280px;
            z-index: 31;
        }
        .b-fixed {
            top: 58px;
            max-width: 1280px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

