/* 도움말 & layer */
.help {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    background-color: #fff;
    background-image: linear-gradient(180deg, rgba(17,17,17,0) 0%, rgba(17,17,17,0.04) 100%);
    border: 1px solid #ccc;
    color: transparent;
    font-size: 5px;
    vertical-align: middle;
    border-radius: 50%;

    &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 5px;
        width: 6px;
        height: 9px;
        background: url('/MW/img/common/help_img.png') no-repeat 0 0;
        background-size: 100% 100%;
    }
}
button.help{
    width: 18px;
    height: 18px;
}
.help_b {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 22px;
    height: 22px;
    background: #fafafa;
    border: 1px solid #ab9076;
    color: transparent;
    font-size: 5px;
    vertical-align: middle;
    text-indent: -1000em;

    &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 7px;
        width: 7px;
        height: 11px;
        background: url('/MW/img/common/help_b_img.png') no-repeat 0 0;
        background-size: 100% 100%;
    }
}

.lyw {
    display: none;
    position: absolute;
    left: 0;
    overflow: hidden;
    width: 100%;
    z-index: 30;

    .lym {
        overflow: hidden;
        min-height: 38px;
        margin: 0 8px;
        border: 1px solid #d1d1d6;
        background: #fff;
    }

    .cls {
        position: absolute;
        overflow: hidden;
        top: 1px;
        right: 8px;
        width: 48px;
        height: 44px;
        padding: 0;
        border: none;
        background: none;
        text-indent: -10000px;

        &:before, &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 1px;
            height: 22px;
            margin: -11px 0 0 0;
            background: #333;
            transform: rotate(45deg);
        }
        &:after {
            width: 22px;
            height: 1px;
            margin: 0 0 0 -11px;
        }
    }

    .lyc {
        margin: 12px 15px 15px;
    }
}
.lym .lyc:first-child {
        margin: 12px 15px;
}

.lym > h2, .lym > h3 {
    padding: 13px 15px 11px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f4f4f4;
    color: #333;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
}

.lyw .lym > *:first-child:before {
    content: '';
    float: right;
    width: 30px;
    height: 25px;
}

.app_lyr .lyc {
    padding: 12px 15px 15px;
}
