/* GNB 상단배너 */
.head_bnr {
    overflow: hidden;
    position: relative;
    z-index: 2;

    a {
        display: block;
        background-color: #e7e7ea;

        img {
            display: block;
            width: 320px;
            height: 51px;
            margin: auto;
        }
    }

    button {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        width: 45px;
        height: 50px;
        background: none;
        border: none;
        text-indent: -200px;

        &:before, &:after {
            content: '';
            position: absolute;
            left: auto;
            right: 50%;
            top: 50%;
            width: 1px;
            height: 20px;
            margin: -10px 0 0 -1px;
            background: #575c62;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
        @media screen and (max-width:358px) {
            & {
                right: -7px;
            }
        }
    }
}
