/* swipe관련 */
.mwContainer {
    position: relative;
    width: 100%;
    overflow: visible;

    html.android & a:hover {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}

.mwView {
    width: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
}

/* swiper */
.swiper-slide-preload {
    position: absolute;
    top: 0;
    left: 0;
}
