/* error */
.msg9_new {
    clear: both;
    overflow: hidden;
    margin: 14px 10px 16px;
    border: 1px solid #ababab;

    .logo {
        padding-bottom: 17px;
        margin: 0 6px;
        border-bottom: 1px dashed #c8c8c8;
        text-align: center;
        font-size: 18px;
        color: #3b3b3b;
        font-weight: bold;
        line-height: 1.2;

        strong {
            color: #f30904;
        }

        span {
            display: block;
            width: 160px;
            margin: 0 auto;
            text-indent: -1000em;
        }

        .err {
            height: 99px;
            margin-bottom: 6px;
            background: url("/MW/img/etc/error_msg.png") no-repeat 50% 50%;
        }

        .msg {
            width: 264px;
            height: 66px;
            margin-top: 20px;
            background: url("/MW/img/etc/error_msg2.png") no-repeat 50% 50%;
        }
    }

    .txt {
        overflow: hidden;
        padding: 14px 10px;
        font-size: 13px;
        color: #3b3b3b;
        line-height: 1.2;
        text-align: center;
    }

    .txt2 {
        overflow: hidden;
        padding: 10px 10px 8px 10px;
        border-top: 1px solid #ababab;
        background: #f1f1f1;

        li {
            overflow: hidden;
            position: relative;
            padding: 0 0 1px 6px;
            font-size: 13px;
            color: #666;
            line-height: 1.2;

            &:before {
                content: '';
                position: absolute;
                top: 6px;
                left: 0;
                width: 2px;
                height: 2px;
                background: #666;
            }
        }
    }
}
