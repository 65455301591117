/* common-공지사항 */
.ntc_lst {
    margin-top: 8px;
    padding: 6px 0 0;
    border-top: 1px solid #ababab;

    li {
        position: relative;
        padding-left: 8px;
        margin-top: 2px;
        color: #666;
        font-size: 12px;
        line-height: 1.25em;

        &:before {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            width: 4px;
            height: 1px;
            background: #8c7458;
        }

        &.red {
            color: #8c7458 !important;

            &:before {
                background: #8c7458;
            }
        }
    }
}
