.kwd {
    display: none;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid #96a4ae;
    background: #fafafa;

    ul {
        overflow: hidden;
        float: left;
        width: 50%;
        background: #fff;

        &:nth-child(odd) {
            border-right: 1px solid #d5d4d9;
            margin-left: -1px;
        }
    }

    li {
        overflow: hidden;
        height: 34px;
        margin-bottom: -1px;
        border-bottom: 1px solid #d5d4d9;

        a {
            display: block;
            overflow: hidden;
            height: 15px;
            padding: 10px;
            color: #666;
            font-size: 13px;
            font-weight: bold;
            line-height: 1.4;
            white-space: nowrap;
            text-overflow: ellipsis;

            strong {
                color: #e71818;
                font-weight: normal;
            }
        }
    }

    .btn_close {
        position: absolute;
        bottom: 9px;
        right: 10px;
    }
}

.btn_close {
    overflow: hidden;
    height: 26px;
    padding: 0 27px 0 12px;
    border: 1px solid #d5d4d9;
    border-width: 0 1px 1px 1px;
    background: #fff;
    border-radius: 0 0 3px 3px;
    color: #666;
    font-size: .7em;
    font-weight: bold;
    line-height: 26px;
    text-align: center;

    &:before, &:after {
        content: '';
        position: absolute;
        left: auto;
        right: 14px;
        top: 6px;
        width: 3px;
        height: 12px;
        background: #979eb3;
        box-shadow: inset 0 0 0 #979eb3;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

button.btn_close {
    height: 27px;
}

.kword {
    display: none;

    .ctg {
        padding: 10px 15px 17px;
        border-bottom: 1px solid #edecf2;
        background: #fff;

        h2 {
            color: #111;
            font-size: 15px;
            font-weight: normal;
            line-height: 20px;
        }

        li {
            margin-top: 7px;

            a {
                display: block;
                overflow: hidden;
                position: relative;
                height: 20px;
                padding-left: 21px;
                color: #5f7fe4;
                font-size: 15px;
                line-height: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 19px;
                    background: url("/MW/img/common/kword.png") no-repeat 0 0;
                    background-size: 20px 175px;
                    content: '';
                }
            }

            &.m2 a:before {
                background-position: 0 -20px;
            }

            &.m3 a:before {
                background-position: 0 -40px;
            }

            &.m4 a:before {
                background-position: 0 -60px;
            }

            &.m5 a:before {
                background-position: 0 -80px;
            }

            &.m6 a:before {
                background-position: 0 -100px;
            }

            &.m7 a:before {
                background-position: 0 -120px;
            }

            &.m8 a:before {
                background-position: 0 -140px;
            }
        }
    }

    .vrt {
        margin-top: 17px;
        font-size: 14px;
        line-height: 20px;

        span {
            color: #333;
        }

        .type {
            display: inline-block;
            height: 18px;
            padding: 0 7px;
            margin-right: 6px;
            border: 1px solid #333;
            border-radius: 9px;
            font-size: 11px;
            line-height: 18px;
            vertical-align: middle;
        }
        &.top {
            display: block;
            height: 45px;
            line-height: 45px;
            margin-top: 0;
            padding-left: 15px;
            border-bottom: 1px solid #edecf2;
            background-color: #fff;
        }
    }

    .wrd {
        background: #fff;

        li {
            position: relative;
            border-bottom: 1px solid #edecf2;

            &:first-child {
                border-top: none;
            }

            a {
                display: block;
                overflow: hidden;
                height: 45px;
                padding: 0 40px 0 15px;
                color: #333;
                font-size: 16px;
                line-height: 45px;
                white-space: nowrap;
                text-overflow: ellipsis;

                strong {
                    color: #f62e3d;
                    font-weight: normal;
                }
            }

            button {
                overflow: hidden;
                position: absolute;
                top: 0;
                right: 0;
                width: 40px;
                height: 45px;
                border: none;
                background: none;
                color: transparent;

                &:before {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    width: 14px;
                    height: 14px;
                    background: url("/MW/img/common/kword.png") no-repeat 0 -160px;
                    background-size: 20px 175px;
                    content: '';
                }
            }
        }
    }
}

.schwd {
    display: none;
    overflow: hidden;
    position: relative;
    padding: 10px 8px;

    .sw_tab {
        display: table;
        width: 100%;
        background: #e2e3e7;
        margin-bottom: 10px;

        li {
            display: table-cell;
            width: 33.3%;
            border: 1px solid #cbcccf;
            border-width: 1px 0 1px 1px;
            text-align: center;

            &:last-child {
                border-right-width: 1px;
            }

            a {
                display: block;
                height: 2.4em;
                color: #6e7077;
                font-size: .95em;
                font-weight: bold;
                line-height: 2.3em;
            }

            &.on {
                border-color: #868ca7;
                border-right-width: 1px;
                background: #8f94ae;

                + li {
                    border-left-width: 0;
                }

                a {
                    color: #fff;
                }
            }

            .pg {
                display: none;
                overflow: hidden;
                height: 4px;
                margin-top: -6px;
                line-height: 4px;

                span {
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    margin: 0 2px 0 3px;
                    border-radius: 2px;
                    background: #676c8a;
                    vertical-align: top;

                    &.on {
                        background: #fff;
                    }
                }

                b {
                    display: block;
                    overflow: hidden;
                    height: 4px;
                    margin-top: -4px;
                    font-size: 4px;
                    color: transparent;
                    line-height: 4px;
                }
            }

            &.on .pg {
                display: block;
            }
        }
    }
}

.sw_ft .close {
    position: relative;
    height: 28px;
    border: none;
    background: #abb0b9;
    padding: 0 24px 0 10px;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;

    &:before, &:after {
        content: '';
        position: absolute;
        left: auto;
        right: 14px;
        top: 8px;
        width: 2px;
        height: 12px;
        background: #fff;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

header .sw_ft {
    margin-top: 6px;
    text-align: right;
}

header .kword .sw_ft {
    margin-right: 10px;
}

.schwd .sw_time {
    padding: 10px;
    color: #919295;
    font-size: .75em;
    line-height: 1.1em;
}

.sw_con {
    position: relative;
    margin: 0 8px;

    ul {
        border: 1px solid #dfdfe3;
    }

    li {
        position: relative;
        background: #fff;
        border-top: 1px solid #dfdfe3;
        font-size: .95em;
        line-height: 2.8em;

        &:first-child {
            border-top: none;
        }

        a {
            display: block;
            overflow: hidden;
            height: 2.75em;
            margin: 0 35px 0 10px;
            color: #333;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .none {
        padding: 60px 0;
        background: #fff;
        color: #abb0b9;
        font-size: .85em;
        line-height: 3em;
        text-align: center;
    }

    &.rec li button {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 40px;
        border: none;
        background: none;
        color: transparent;

        &:before, &:after {
            content: '';
            position: absolute;
            left: auto;
            right: 16px;
            top: 50%;
            width: 1px;
            height: 16px;
            margin-top: -8px;
            background: #abb0b9;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &.rec .del_all {
        border: none;
        background: none;
        padding: 10px;
        color: #808793;
        font-size: .85em;

        &:after {
            content: '';
            display: inline-block;
            width: 13px;
            height: 12px;
            margin-left: 4px;
            background: url("/MW/img/common/schword.png") no-repeat 0 0;
            background-size: 53px 12px;
            vertical-align: -1px;
        }
    }

    &.fast li a {
        margin: 0 43px 0 33px;
    }

    li a b {
        overflow: hidden;
        position: absolute;
        left: 9px;
        top: 50%;
        width: 14px;
        height: 13px;
        margin-top: -7px;
        background: #949dac;
        border: 1px solid #8c95a4;
        border-radius: 2px;
        color: #fff;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
    }
}

.sw_con li.rk a {
    color: #e90e2d;

    b {
        border-color: #f13550;
        background: #f05168;
    }
}

.sw_con .rt {
    position: absolute;
    right: 9px;
    top: 0;
    min-width: 43px;
    color: #999;
    font-size: 13px;
    text-align: right;
}

.sw_con .rt span {
    overflow: hidden;
    display: inline-block;
    width: 8px;
    height: 12px;
    margin-left: 5px;
    background-image: url("/MW/img/common/schword.png");
    background-size: 56px 12px;
    color: transparent;
    vertical-align: -.2em;
}

.sw_con .rt.up span {
    background-position: -14px 0;
}

.sw_con .rt.dw span {
    background-position: -23px 0;
}

.sw_con .rt.sm span {
    background-position: -32px 0;
}

.sw_con .rt.nw {
    color: #f85864;
    font-weight: bold;
}

.sw_con.ppu li a {
    margin: 0 10px 0 33px;
}

.sw_con .swp {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.schwd {
    & .prev, & .next {
        overflow: hidden;
        position: absolute;
        top: 50%;
        width: 24px;
        height: 46px;
        margin-top: -39px;
        background: rgba(0, 0, 0, 0.2);
        border: none;
        text-indent: -100px;
        z-index: 11;
    }

    & .prev {
        left: 0;
        border-radius: 0 2px 2px 0;
    }

    & .next {
        right: 0;
        border-radius: 2px 0 0 2px;
    }

    & .prev:before, & .next:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7px;
        height: 11px;
        margin: -6px 0 0 -4px;
        background: url("/MW/img/common/schword.png") no-repeat -41px 0;
        background-size: 56px 12px;
    }

    & .next:before {
        background-position: -49px 0;
    }
}

.sw_swp {
    overflow: hidden;
    position: relative;
    margin: 0 -8px;
}

body.totalSearchType {
    background: #eee;

    > *:not(#wrap) {
        display: none !important;
    }

    #wrap > *:not(header) {
        display: none !important;
    }

    .head_bnr {
        display: none !important;
    }
}
