@import "../helpers/variables";
@import "../sprites/sp_gnb_ver6";

.c-gnb-redface {
    position: relative;
    background-color: $color-11st-red;
    color: #fff;

    &__menu {
        height: 52px;
    }

    &__logo {
        @include size(56px 36px);

        position: absolute;
        top: 8px;
        left: 52px;
        display: block;
        margin: 0;
    }

    &__gohome {
        @include sprite-retina($sp_gnb_ver6_logo_w);

        display: block;
        text-indent: -9999px;
    }

    &__sidemenu {
        @include sprite-retina($sp_gnb_ver6_menu_w);

        position: absolute;
        top: 8px;
        left: 8px;
        text-indent: -9999px;
    }

    .c-badge {
        min-width: 11px;
        max-width: 11px;
        height: 12px;
        padding: 0 3px 0 3px;
        border-radius: 0;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 6px;
        position: absolute;
        top: 18px;
        left: initial;
        right: 18px;
        box-sizing: initial;
        display: block;
        color: $color-11st-red;
        background-color: #fff;
        font-size: 12px;
        font-weight: bold;
        font-family: Helvetica;
        text-align: center;
        text-indent: -1px;
        line-height: 12px;
        white-space: nowrap;
        user-select: none;

        &--digit3 {
            padding: 0 4px 0 2px;
            font-size: 10px;
            line-height: 13px;
        }
    }

    &__cart {
        @include sprite-retina($sp_gnb_ver6_cart_w);

        position: absolute;
        top: 8px;
        right: 10px;
        text-indent: -9999px;

        .c-badge {
            top: 10px;
            right: 8px;
        }
    }

    &__search {
        padding: 0 8px 8px;
        height: 44px;

        &-box {
            height: 36px;
            position: relative;
            padding-left: 36px;
            box-sizing: border-box;
            background-color: $color-11st-red;

            input[type="search"] {
                @include size(100% 36px);

                border: 0;
                font-size: 14px;
            }

            input[type="submit"] {
                @include sprite-retina($sp_gnb_ver6_search_s);

                position: absolute;
                left: 0;
                top: 0;
                border: 0;
                margin: 0;
                background-color: #fff;
                text-indent: -9999px;
            }

            button[type="button"] {
                @include sprite-retina($sp_gnb_ver6_cancel_g);

                position: absolute;
                top: 6px;
                right: 8px;
                border: 0;
                background-color: transparent;
                text-indent: -9999px;
            }
        }

        &-back {
            @include sprite-retina($sp_gnb_ver6_close_w);

            text-indent: -9999px;
            background-color: $color-11st-red;
            border: 0;
            display: none;
        }

        .totalSearchType &, .tourSearchType & {
            position: relative;
            padding: 4px;
            height: 44px;

            .c-gnb-redface__search-box {
                padding-left: 0;
                padding-right: 85px;
                height: 44px;

                & input[type="search"] {
                    height: 44px;
                    color: #111;
                    font-size: 17px;
                    font-weight: bold;
                    padding-right: 34px;
                    padding-left: 8px;
                    border: 1px solid #cf1525;
                }

                & input[type="submit"] {
                    @include sprite-retina($sp_gnb_ver6_search_w);

                    // @include size(44px);

                    // background-position: -46px -121px;
                    right: 43px;
                    left: initial;
                    top: 0;
                    background-color: #fe4353;
                    border: 1px solid #cf1525;
                }

                & button[type="button"] {
                    top: 9px;
                    right: 91px;
                }
            }

            .c-gnb-redface__search-back {
                position: absolute;
                top: 15px;
                right: 14px;
                display: block;
            }
        }
    }

    &__search-icon {
        @include sprite-retina($sp_gnb_ver6_search_w);

        position: absolute;
        top: 3px;
        right: 50px;
        overflow: hidden;
        white-space: nowrap;
        text-indent: 100%;
    }
}
