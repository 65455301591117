/* GNB New - 2017-03 */

// 공통 GNB 스타일 - c-redface ( main, list, vertical, detail 공통 부분)
@import "gnb_common";

.totalSearchType .c-gnb-redface--vertical .c-gnb-redface__search, .tourSearchType .c-gnb-redface--vertical .c-gnb-redface__search {
    .c-gnb-redface__search-box {
        padding-right: 165px;

        button[type="button"] {
            right: 171px;
        }

        input[type="submit"] {
            position: absolute;
            left: initial;
            right: 123px;

            &.c-gnb-redface__search-all {
                @include size(72px 44px);

                background-color: #fe4353;
                border: 1px solid #cf1525;
                background-image: none;
                right: 43px;
                text-indent: 0;
                color: #fff;
                font-size: 15px;
            }
        }
    }
}

.c-gnb-redface--listing {
    .c-gnb-redface__search {
        padding: 4px 53px 4px 116px;

        &-box {
            height: 44px;
            padding-left: 0;

            input[type="search"] {
                height: 44px;
                color: #111;
                border: 1px solid #cf1525;
                font-size: 17px;
                font-weight: bold;
                padding-right: 34px;
            }

            input[type="submit"] {
                display: none;
            }

            button[type="button"] {
                top: 9px;
                right: 6px;
            }
        }
    }

    .totalSearchType & {
        .c-gnb-redface__search {
            padding-left: 4px;
            padding-right: 4px;

            input[type="submit"] {
                display: block;
            }
        }
    }
}

.c-gnb-redface--sub {
    .c-gnb-redface__search {
        display: none;
    }
}

.c-gnb-redface--vertical {
    .c-gnb-redface__service-title {
        display: block;
        position: absolute;
        top: 9px;
        left: 118px;
        height: 36px;

        a {
            display: block;
            text-indent: -9999px;
        }
    }

    &-now {
        .c-gnb-redface__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_now);
        }
    }

    &-life {
        .c-gnb-redface__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_homecar);
        }
    }

    &-brand {
        .c-gnb-redface__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_brand);
        }
    }

    &-mart {
        .c-gnb-redface__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_mart);
        }
    }

    &-tour {
        .c-gnb-redface__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_travel);
        }
    }

    &-ticket {
        .c-gnb-redface__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_ticket);
        }
    }

    &-overseas {
        .c-gnb-redface__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_overseas);
            margin-top: -1px;
        }
    }

    &-roadshop {
        .c-gnb-redface__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_roadshop);
            margin-top: -1px;
        }
    }

    .c-gnb-redface__search {
        display: none;
    }
}
