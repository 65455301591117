$path-r-coupon-down-image: "/MW/img/order/";
$path-r-coupon-down-svg: "/MW/img/svg/logo/";
$gradient-r-coupon-down-dotline: linear-gradient(rgba(255, 255, 255, 0) 49%, rgba(255, 255, 255, 1) 50%) repeat-y 0 0;

@import "../../svg/sp_common";

//쿠폰 다운 팝업 (PC와 공통사용 - 소스 수정시 주의필요)
//작업 후 PC에 소스 전달, 파일명/파일경로 다를 수 있으니 주의해서 전달
.r-coupon-down {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fafafa;
    z-index: 105;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    .sr-only {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        margin: -1px;
        width: 1px;
        height: 1px;
        font-size: 6px;
        line-height: 1;
        white-space: nowrap;
    }
    .sum_clc {
        margin: 0 0 20px;
        padding: 20px 18px 24px;
        background: #fff;

        ul {
            display: table;
            width: 100%;
        }
        li {
            display: table-cell;
            position: relative;
            width: 33.33%;
            padding: 0 4px;
            color: #333;
            text-align: center;
            white-space: nowrap;
            vertical-align: top;

            > div {
                display: inline-block;
                text-align: right;
            }
            em {
                display: block;
                height: 16px;
                padding-right: 1px;
                font-size: 13px;
                line-height: 16px;
            }
            .prc {
                display: block;
                margin-top: 7px;
                font-size: 17px;
                line-height: 20px;
                white-space: nowrap;
            }
            .oprc {
                display: block;
                color: #999;
                font-size: 13px;
                line-height: 16px;
                text-decoration: line-through;
            }
            .sprc {
                display: block;
                margin-left: -40px;
                color: $color-11st-red;
                font-size: 13px;
                line-height: 16px;
            }
            &:nth-child(2) {
                color: $color-11st-red;

                &:before {
                    content: "";
                    position: absolute;
                    top: 30px;
                    left: -4px;
                    width: 8px;
                    height: 1px;
                    background: #333;
                }

                .prc {
                    font-weight: bold;
                }
            }
            &:nth-child(3) {
                &:before {
                    content: "";
                    position: absolute;
                    top: 28px;
                    left: -4px;
                    width: 8px;
                    height: 2px;
                    border: solid #333;
                    border-width: 1px 0;
                }
                .prc {
                    color: #111;
                    font-weight: bold;
                }
            }
        }
        &.sum_clc_fix {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            margin: 0;
            padding: 16px 16px 12px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
            z-index: 106;
            box-sizing: border-box;

            li .prc {
                margin-top: 4px;
            }
        }
    }
    &__text {
        margin: 0 0 20px;
        padding: 10px 0 20px;
        font-size: 13px;
        color: $color-gray-03;
        line-height: 20px;
        text-align: center;
        background: $color-gray-14;
        border-bottom: 1px solid $color-gray-11;
    }
    &__bottom {
        display: flex;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #fff;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        z-index: 106;
        box-sizing: border-box;

        //개편후 삭제 - start
        button {
            display: block;
            height: 56px;
            border: none;
            color: #fff;
            font-size: 17px;
        }
        .btn_cancel {
            width: 100px;
            background: #b4373a;

            &:last-of-type {
                background: $color-11st-red;
                flex: 1;
            }
        }
        .btn_down_all {
            width: 100%;
            background: $color-11st-red;
            flex: 1;

            &:disabled {
                background: #ccc;

                &:hover {
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                }
            }
        }
        //개편후 삭제 - end
        .r-coupon-down__button-cancel {
            display: block;
            width: 96px;
            height: 48px;
            margin: 8px 0 8px 8px;
            border: 1px solid #ddd;
            border-radius: 4px;
            background: #fff;
            color: #333;
            font-size: 16px;
            font-weight: bold;

            &:last-of-type {
                flex: 1;
                margin-right: 8px;
            }
        }
        .r-coupon-down__button-down-all {
            display: block;
            width: 100%;
            height: 48px;
            margin: 8px;
            border: none;
            border-radius: 4px;
            background: $color-11st-red;
            color: #fff;
            font-size: 16px;
            font-weight: bold;

            &:disabled {
                background: #ccc;

                &:hover {
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                }
            }
        }
    }
    &__close {
        position: absolute;
        top: 0;
        right: 0;
        width: 52px;
        height: 52px;
        border: none;
        background: none;
        color: transparent;
        font-size: 10px;
        z-index: 20;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1px;
            height: 25px;
            margin-top: -13px;
            background: #333;
            content: "";
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    &__cont {
        overflow: hidden;
        position: relative;
        background: #fafafa;
    }
    &__product {
        overflow: hidden;
        padding-bottom: 72px;

        .tit {
            padding: 20px 0 0;
            background: #fff;
            color: #111;
            font-size: 20px;
            font-weight: bold;
            line-height: 22px;
            text-align: center;
        }
        .ttxt {
            margin: 32px 0 12px;
            color: #111;
            font-size: 17px;
            font-weight: bold;
            line-height: 24px;
            text-align: center;
        }
        .stit {
            margin: 24px 16px 12px;
            color: #333;
            font-size: 14px;
            font-weight: normal;
            line-height: 17px;
        }
        //개편후 삭제 - start
        .cdown_lst {
            margin: 12px 16px 0;

            li {
                margin-top: 4px;
            }
        }
        .cdown {
            position: relative;
            padding: 17px 56px 13px 17px;
            border-radius: 4px;
            background: #fff;
            color: #333;
            font-size: 13px;
            line-height: 20px;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px solid #eee;
                border-radius: 4px;
            }

            .prc {
                float: left;
                width: 78px;
                color: $color-11st-red;
                font-size: 16px;
                font-weight: bold;
                line-height: 20px;
            }
            .icon {
                display: inline-block;
                padding: 0 8px;
                margin: 0 0 6px 0;
                border: 1px solid #333;
                border-radius: 17px;
                color: #333;
                font-size: 12px;
                font-weight: bold;
                line-height: 18px;
                vertical-align: top;

                &.icon_cart {
                    border-color: $color-11st-red;
                    color: $color-11st-red;
                }
                &.icon_delivery {
                    border-color: $color-11st-blue;
                    color: $color-11st-blue;
                }
            }
            > div {
                overflow: hidden;
            }
            .pay,
            .name,
            .cont,
            .terms {
                display: block;
            }
            %style-r-coupon-down-cdown-btn {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 48px;
                border-radius: 0 4px 4px 0;
            }
            .down {
                @extend %style-r-coupon-down-cdown-btn;
                width: 49px;
                background: linear-gradient($color-11st-red 49%, #fff 50%) repeat-y 0 0;
                background-size: 1px 4px;
                background-color: $color-11st-red;
                border: none;
                color: #fff;
                font-size: 13px;

                span {
                    display: block;
                    overflow: hidden;
                    width: 24px;
                    height: 24px;
                    margin: 1px auto;
                    color: transparent;
                    font-size: 10px;
                    background: url($path-r-coupon-down-image + "ico_coupon_down.png") no-repeat 0 0;
                    background-size: 100% 100%;
                }
            }
            .end {
                @extend %style-r-coupon-down-cdown-btn;
                background: linear-gradient(rgba(238, 238, 238, 1) 49%, rgba(238, 238, 238, 0) 50%) repeat-y 0 0;
                background-size: 1px 4px;
                color: #333;
                font-size: 13px;
                line-height: 15px;
                font-weight: bold;
                text-align: center;

                span {
                    display: block;
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    transform: translateY(-50%);
                }
            }
            .apply {
                @extend %style-r-coupon-down-cdown-btn;
                background: linear-gradient(rgba($color-11st-red, 1) 49%, rgba($color-11st-red, 0) 50%) repeat-y 0 0;
                background-size: 1px 4px;
                color: $color-11st-red;
                font-size: 13px;
                font-weight: bold;
                line-height: 19px;
                text-align: center;

                span {
                    display: block;
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    transform: translateY(-50%);
                }
            }
            &.cdelivery {
                .prc {
                    color: $color-11st-blue;
                }
            }
            &.capply {
                &:before {
                    border-color: $color-11st-red;
                }
                .icon {
                    border-color: $color-11st-red;
                    color: $color-11st-red;
                }
                > div,
                .prc {
                    color: $color-11st-red;
                }
            }
        }
        .cdown_non {
            margin: 100px 0;

            p {
                color: #111;
                font-size: 16px;
                line-height: 24px;
                text-align: center;

                &:before {
                    content: "";
                    display: block;
                    width: 46px;
                    height: 46px;
                    margin: 0 auto 20px;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                    background: linear-gradient(#ddd, #ddd) no-repeat 50% 11px, linear-gradient(#ddd, #ddd) no-repeat 50% 32px;
                    background-size: 3px 18px, 3px 3px;
                }
            }
        }
        //개편후 삭제 - end
    }
    //.r-coupon-down__list
    &__list {
        margin: 12px 16px 0;
        letter-spacing: 0;

        li {
            margin-top: 12px;
        }

        %style-r-coupondown-icon {
            float: left;
            padding: 1px 8px 0;
            margin: 0 4px 3px 0;
            border-radius: 20px;
            font-size: 11px;
            line-height: 19px;
        }
        .r-couponbox {
            position: relative;
            padding: 20px 50px 15px 17px;
            border-radius: 8px;
            background: #fff;
            color: #666;
            font-size: 13px;
            line-height: 20px;
            box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.16);

            &:before,
            &:after {
                content: "";
                position: absolute;
                right: 35px;
                z-index: 2;
            }
            &:before {
                top: -5px;
                width: 26px;
                height: 11px;
                background: url($path-r-coupon-down-image + "bg_coupon_top.png") no-repeat 0 0;
                background-size: 100% 100%;
            }
            &:after {
                bottom: -9px;
                width: 26px;
                height: 15px;
                background: url($path-r-coupon-down-image + "bg_coupon_bottom.png") no-repeat 0 0;
                background-size: 100% 100%;
            }
            &__price {
                float: left;
                margin-right: 8px;
                margin-bottom: 4px;
                color: $color-11st-red;
                font-size: 18px;
                font-family: -apple-system, BlinkMacSystemFont, Roboto, "Noto Sans KR", "Malgun Gothic", arial, sans-serif; //$font-family3
                font-weight: bold;
                line-height: 24px;
                background: linear-gradient(90deg, #ff5a2e, #ff0038 52%, #ff00ef);
                background-image: -ms-linear-gradient(#fff, #fff); //ie미적용관련 코드
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                strong {
                    font-size: 24px;
                    vertical-align: -1px;
                }

                @media screen and (max-width: 359px) {
                    font-size: 16px;

                    strong {
                        font-size: 20px;
                    }
                }
            }
            &__icon {
                @extend %style-r-coupondown-icon;
                border: 1px solid rgba(51, 51, 51, 0.2);
                color: #333;
            }
            &__icon-store {
                @extend %style-r-coupondown-icon;
                border: 1px solid rgba($color-11st-red, 0.3);
                color: $color-11st-red;
            }
            &__icon-sktpass {
                @extend %style-r-coupondown-icon;
                @include get-sprite-common("logo_sktpass", 70);
                overflow: hidden;
                position: relative;
                margin-top: 1px;
                color: transparent !important;
                box-sizing: border-box;
            }
            &__name {
                display: block;
                clear: both;
                color: #333;
                font-size: 15px;
                font-weight: bold;
                line-height: 20px;

                & + .r-couponbox__name {
                    margin-top: 2px;
                }
            }
            &__term {
                display: block;
                margin-top: 2px;
            }
            %style-r-coupondown-button {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 48px;
            }
            &__button-down {
                @extend %style-r-coupondown-button;
                width: 49px;
                background: $gradient-r-coupon-down-dotline, linear-gradient(151deg, #ff5a2e, #ff0038 52%, #ff00ef);
                background-size: 1px 8px, 100% 100%;
                background-color: $color-11st-red;
                border: none;
                color: #fff;
                font-size: 13px;

                &:before {
                    content: "";
                    display: block;
                    overflow: hidden;
                    width: 24px;
                    height: 24px;
                    margin: 1px auto;
                    color: transparent;
                    font-size: 10px;
                    background: url($path-r-coupon-down-image + "ico_coupon_down.png") no-repeat 0 0;
                    background-size: 100% 100%;
                }
                span {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    border: 0;
                    font-size: 6px;
                    line-height: 1;
                    white-space: nowrap;
                }
            }
            &__button-join {
                @extend %style-r-coupondown-button;
                width: 49px;
                background: $gradient-r-coupon-down-dotline, linear-gradient(151deg, #7e64ff, #3617ce 55%, #250c9f 87%);
                background-size: 1px 8px, 100% 100%;
                border: none;
                color: #fff;
                font-size: 13px;
                text-align: center;

                span {
                    display: block;
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    transform: translateY(-50%);
                }
            }
            &__down-end {
                @extend %style-r-coupondown-button;
                background: #ccc $gradient-r-coupon-down-dotline;
                background-size: 1px 8px;
                color: #fff;
                font-size: 13px;
                line-height: 15px;
                font-weight: bold;
                text-align: center;

                span {
                    display: block;
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    transform: translateY(-50%);
                }
            }
            &__apply {
                @extend %style-r-coupondown-button;
                background: $gradient-r-coupon-down-dotline, linear-gradient(151deg, #ff5a2e, #ff0038 52%, #ff00ef);
                background-size: 1px 8px, 100% 100%;
                color: #fff;
                font-size: 13px;
                font-weight: bold;
                line-height: 19px;
                text-align: center;

                span {
                    display: block;
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    transform: translateY(-50%);
                }
            }
            &.r-couponbox--delivery {
                .r-couponbox__price {
                    color: $color-11st-blue;
                }
            }
            &.r-couponbox--sktpass {
                .r-couponbox__price {
                    color: #3617ce;
                    background-image: linear-gradient(102deg, #7e64ff, #3617ce 55%, #250c9f 87%);
                    background-image: -ms-linear-gradient(#fff, #fff); //ie미적용관련 코드
                }
                .r-couponbox__apply {
                    background: $gradient-r-coupon-down-dotline, linear-gradient(151deg, #7e64ff, #3617ce 55%, #250c9f 87%);
                    background-size: 1px 8px, 100% 100%;
                }
            }
            //&.r-couponbox--apply > 적용중 케이스는 기본디자인과 같은 디자인으로 변경

            &.r-couponbox--apply-mo {
                .r-couponbox__price {
                    color: #00ae6f;
                    background-image: linear-gradient(102deg, #33d298, #00ae6f 55%, #0e9967 87%);
                    background-image: -ms-linear-gradient(#fff, #fff); //ie미적용관련 코드
                }
                .r-couponbox__apply {
                    background: $gradient-r-coupon-down-dotline, linear-gradient(151deg, #33d298, #00ae6f 55%, #0e9967 87%) no-repeat 0 0;
                    background-size: 1px 8px, 100% 100%;
                }
            }
        }
    }
    //.r-coupon-down__none
    &__none {
        margin: 100px 0;

        p {
            color: #111;
            font-size: 16px;
            line-height: 24px;
            text-align: center;

            &:before {
                content: "";
                display: block;
                width: 46px;
                height: 46px;
                margin: 0 auto 20px;
                border: 1px solid #ddd;
                border-radius: 50%;
                background: linear-gradient(#ddd, #ddd) no-repeat 50% 11px, linear-gradient(#ddd, #ddd) no-repeat 50% 32px;
                background-size: 3px 18px, 3px 3px;
            }
        }
    }
    &__line-banner {
        margin: 16px;

        a {
            display: block;
            overflow: hidden;
            background-color: #ff84ba;
            border-radius: 4px;
        }
        img {
            display: block;
            width: 288px;
            max-height: 52px;
            margin: auto;
            border-radius: 4px;
        }
        &--beauty {
            img {
                width: 300px;
                max-height: 60px;
            }
        }
    }
    &__notice {
        margin: 12px 16px 0;
        padding: 12px 16px;
        font-size: 12px;
        color: $color-gray-02;
        text-align: center;
        background: $color-gray-12;
        border-radius: 4px;
        strong {
            color: $color-11st-red;
            font-weight: normal;
        }
    }
}

.r-plus-coupon {
    display: none;
    position: fixed;
    top: 50% !important;
    left: 50%;
    width: 296px;
    background: #fff;
    transform: translate(-50%, -50%);
    z-index: 110;
    border-radius: 8px;

    &.pop_plus_coupon_app_v2 {
        position: relative;
        top: 0 !important;
        left: 0;
        transform: none;
    }
    &__close {
        position: absolute;
        overflow: hidden;
        top: 0;
        right: 0;
        width: 42px;
        height: 50px;
        padding: 0;
        border: none;
        background: none;
        color: transparent;
        text-indent: -100px;
        transform: translateZ(0);

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 1px;
            height: 19px;
            margin: -10px 0 0 -1px;
            background: #666;
            transform: rotate(45deg);
        }
        &:after {
            width: 19px;
            height: 1px;
            margin: -1px 0 0 -10px;
        }
    }
    &__button {
        display: flex;
        margin: 0 16px 16px;
        text-align: center;

        .bbtn {
            display: block;
            width: 100%;
            border: 1px solid $color-11st-red;
            background: $color-11st-red;
            color: #fff;
            font-size: 16px;
            line-height: 46px;
            letter-spacing: 0;
            border-radius: 4px;

            &:before {
                display: inline-block;
                width: 24px;
                height: 24px;
                margin: -1px 6px 1px 0;
                background: url($path-r-coupon-down-image + "ico_store_zzim.png") no-repeat;
                background-size: 100% 100%;
                vertical-align: middle;
                content: "";
            }
        }
        .talk:before {
            background-image: url($path-r-coupon-down-image + "ico_talk.png");
        }
        .ico_empty:before {
            display: none;
        }
    }
    &__cont {
        padding: 32px 16px 24px;
        letter-spacing: 0;

        .tit {
            font-size: 15px;
            color: #111;
            line-height: 22px;
            text-align: center;

            .point {
                color: $color-11st-red;
            }
        }
        .txt {
            margin-top: 16px;
            font-size: 14px;
            color: #666;
            line-height: 20px;
            text-align: center;
        }
        .guide {
            margin-top: 12px;
            font-size: 12px;
            color: #949494;
            line-height: 18px;
            text-align: center;
        }
        .coupon_info_box {
            overflow: hidden;
            margin: 16px 0 -8px;

            & + .coupon_info_box {
                margin-top: 16px;
            }
        }
        .coupon_info {
            position: relative;
            padding: 12px 0 12px 92px;
            border: 1px solid #ddd;
            font-size: 13px;

            &:before,
            &:after {
                position: absolute;
                top: -5px;
                left: 72px;
                z-index: 1;
                width: 9px;
                height: 9px;
                border: 1px solid #ddd;
                border-radius: 100%;
                background: #fff;
                content: "";
            }
            &:after {
                top: auto;
                bottom: -5px;
            }
            .percent,
            .discount {
                display: -webkit-box;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 77px;
                border-right: 1px dashed #ddd;
                font-size: 17px;
                color: $color-11st-red;
                text-align: center;
                -webkit-box-orient: vertical;
                -webkit-box-pack: center;
            }
            .discount {
                font-size: 13px;
                font-weight: normal;

                span {
                    font-size: 17px;
                    font-weight: bold;
                }
            }
            .type {
                display: block;
                margin-bottom: 4px;
                font-weight: bold;
                color: #111;
            }
            .info_txt {
                color: #999;
            }
        }
    }
}
