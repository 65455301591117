/* 외부유입플로팅배너(상단) */
.ext_bnr {
    position: absolute;
    top: 7px;
    left: 50%;
    width: 290px;
    margin-left: -145px;
    z-index: 100;

    a {
        display: block;
        overflow: hidden;
        border-radius: 4px;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, .3);

        img {
            display: block;
            width: 290px;
        }
    }

    button {
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        width: 34px;
        height: 33px;
        border: none;
        background: transparent;
        text-indent: -1000px;
    }
}

/* 하단 floatingBnr */
.todaydeals {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 100;

    a:first-child {
        display: block;
        overflow: hidden;
        position: relative;
        width: 296px;
        height: 130px;
        margin: 0 auto 5px;
        background: #000;
        border: 2px solid #000;
        box-shadow: 0 0 5px rgba(0, 0, 0, .6);
        border-radius: 5px;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }

    .del {
        overflow: hidden;
        position: absolute;
        top: -5px;
        left: 50%;
        width: 30px;
        height: 31px;
        margin-left: 126px;
        border: none;
        box-shadow: none;
        background: url("/MW/img/main/todaydeals_close_r2.png") no-repeat 0 0;
        background-size: 30px 31px;
        text-indent: -1000px;
    }
}

/* 캠페인 플로팅 배너 */
.cp_floating {
    position: fixed;
    bottom: 44px;
    right: 0;
    z-index: 100;

    .cp_wrap {
        height: 118px;
        padding-left: 40px;
        margin-right: -270px;
        transform: translateX(0);
        transition: transform .5s ease;
    }

    .cp_banner {
        overflow: hidden;
        width: 268px;
        height: 114px;
        border: 2px solid #333c48;
        border-right: none;
        border-radius: 4px 0 0 4px;
        background: #3c4551;

        a {
            display: block;
            float: left;
            width: 246px;
            height: 114px;

            img {
                display: block;
                width: 246px;
                height: 114px;
                border-radius: 2px 0 0 2px;
            }
        }

        .stop {
            float: left;
            width: 22px;
            height: 114px;
            padding: 0 3px;
            border: none;
            border-left: 1px solid #353d48;
            background: #3a444f;
            color: #858c94;
            font-size: .7em;
            line-height: 1.1em;

            &:before {
                content: '';
                display: block;
                width: 9px;
                height: 9px;
                margin: 0 auto 5px;
                background: url("/MW/img/common/campaign_floating_r2.png") no-repeat 0 -102px;
                background-size: 40px 111px;
            }
        }
    }

    .close {
        display: block;
        overflow: hidden;
        position: absolute;
        left: 0px;
        top: 50%;
        width: 40px;
        height: 50px;
        margin-top: -25px;
        border: none;
        background: url("/MW/img/common/campaign_floating_r2.png") no-repeat 0 -51px;
        background-size: 40px 111px;
        text-indent: -1000px;
    }

    &.on .close {
        background-position: 0 0;
    }

    &.on .cp_wrap {
        border: none;
        transform: translateX(-270px);
        transition: transform .5s ease;
    }
}
