.c-gnb-mw--vertical + .c-gnb-mw__search {
    display: none;
}
.totalSearchType .c-gnb-mw--vertical + .c-gnb-redface__search,
.tourSearchType .c-gnb-mw--vertical + .c-gnb-redface__search {
    .c-gnb-redface__search-box {
        padding-right: 165px;
        button[type="button"] {
            right: 171px;
        }
        input[type="submit"] {
            position: absolute;
            left: initial;
            right: 123px;
            &.c-gnb-redface__search-all {
                @include size(72px 44px);
                background-color: #fe4353;
                border: 1px solid #cf1525;
                background-image: none;
                right: 43px;
                text-indent: 0;
                color: #fff;
                font-size: 15px;
            }
        }
    }
}

.c-gnb-mw--vertical {
    .c-gnb-mw__service-title {
        display: block;
        position: absolute;
        top: 9px;
        left: 118px;
        height: 36px;
        a {
            display: block;
            text-indent: -9999px;
        }
    }
    &-now {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_now);
        }
    }
    &-life {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_homecar);
        }
    }
    &-brand {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_brand);
        }
    }
    &-mart {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_mart);
        }
    }
    &-tour {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_travel);
        }
    }
    &-ticket {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_ticket);
        }
    }
    &-overseas {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_overseas);
            margin-top: -1px;
        }
    }
    &-roadshop {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_roadshop);
        }
    }
    &-shockingdeal {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_shockingdeal);
        }
    }
    &-tlounge {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_tlounge);
        }
        &.beta {
            .c-gnb-mw__service-title a {
                position: relative;
                &:after {
                    content: '';
                    background: url("/MW/img/common/ico_beta.png") no-repeat 0 0;
                    background-size: 22px 11px;
                    width: 22px;
                    height: 11px;
                    position: absolute;
                    right: 4px;
                    top: 4px;
                }
            }
        }
    }
    &-outlet {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_outlet);
        }
    }
    &-11festa {
        .c-gnb-mw__service-title a {
            @include sprite-retina($sp_gnb_ver6_ic_gnb_ver_11);
        }
    }
    & + .c-gnb-redface__search {
        display: none;
    }
}
