.c-notification {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 1280px;
    z-index: 103;
    display: none;

    &__txt {
        @include size(100% 65px);
        display: block;
        background: rgba(0, 0, 0, 0.8);
    }
    p {
        padding: 14px 57px 14px 16px;
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        line-height: 19px;
    }
    .close {
        @include size(30px);
        display: block;
        position: absolute;
        top: 18px;
        right: 8px;
        background-color: transparent;
        text-indent: -99999px;
        border: 0;
        &:before,
        &:after {
            content: "";
            position: absolute;
            left: auto;
            right: 50%;
            top: 50%;
            width: 1px;
            height: 20px;
            margin: -10px 0 0 -1px;
            background: #fff;
        }
        &:before {
            @include rotate(45deg);
        }
        &:after {
            @include rotate(-45deg);
        }
    }
    &.on {
        display: block;
        .c-notification__txt {
            display: block;
        }
    }
}
