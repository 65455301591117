/* icon common - 2015 */
.ic {
    display: inline-block;
    overflow: hidden;
    min-width: 44px;
    height: 17px;
    padding: 0 2px;
    margin-right: 1px;
    border: 1px solid #d6d6d6;
    background: #fff;
    color: #92929c;
    font-size: 11px;
    line-height: 18px;
    text-align: center;

    &.ms, &.tm, &.cd, &.mg, &.pt, &.ok {
        border-color: #ffaa9e;
        color: #ff411c;
    }

    &.ms {
        min-width: 64px;
    }

    &.dy, &.fs {
        border-color: #d6d6d6;
        color: #92929c;
    }

    &.cp {
        border-color: #ff3c0d;
        background: #ff3c0d;
        color: #fff;
        font-weight: bold;
    }
}
