@import "../sprites/sp_total_search";

.total_search{
    &_keyword{
        display: none;
        overflow: hidden;
        position: relative;
    }

    &_pagination{
        display: block;
        padding: 9px 0 5px;
        font-size: 0;
        text-align: center;

        .swiper-pagination-bullet{
            display: inline-block;
            overflow: hidden;
            width: 6px;
            height: 6px;
            margin: 0 2px;
            background: rgba(0,0,0,0.2);
            border-radius: 50%;
            color: transparent;
            font-size: 5px;
            vertical-align: top;
            opacity: 1;

            &.swiper-pagination-bullet-active{
                background: #f43142;
            }
        }
    }

    &_swiper{
        margin: 0 4px;

        .swiper-slide{
            width: 81.4% !important;
        }
    }

    &_tit{
        padding: 0 16px;
        background: #fafafa;
        border-bottom: 1px solid #f4f4f4;
        color: #111;
        font-size: 13px;
        font-weight: bold;
        line-height: 44px;
    }

    &_recently{
        overflow: hidden;
        margin: 4px;
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.15);

        li{
            position: relative;

            &:after{
                content: '';
                display: block;
                height: 1px;
                margin: 0 16px;
                background: #f4f4f4;
            }

            &:last-child:after{
                display: none;
            }

            a{
                display: block;
                overflow: hidden;
                padding-left: 16px;
                margin-right: 52px;
                color: #333;
                font-size: 14px;
                line-height: 42px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .del{
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            width: 52px;
            height: 42px;
            background: none;
            border: none;
            color: transparent;

            &:after{
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 36px;
                height: 36px;
                margin: -18px 0 0 -18px;
                border-radius: 50%;
                background: #ddd;
                background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 2px 22px, 22px 2px;
                transform: rotate(45deg) scale(0.5);
            }
        }

        .btn_del_all{
            height: 42px;
            padding: 0 16px;
            background: none;
            border: none;
            color: #666;
            font-size: 12px;

            &:disabled{
                color: #ccc;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            }
        }
    }

    &_bottom{
        overflow: hidden;
        border-top: 1px solid #eee;
        line-height: 42px;
        text-align: right;
    }

    &_popular{
        overflow: hidden;
        margin: 4px;
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.15);

        li{
            position: relative;

            &:after{
                content: '';
                display: block;
                height: 1px;
                margin: 0 16px;
                background: #f4f4f4;
            }

            &:last-child:after{
                display: none;
            }

            a{
                display: block;
                overflow: hidden;
                padding: 0 64px 0 16px;
                color: #333;
                font-size: 14px;
                line-height: 42px;
                white-space: nowrap;
                text-overflow: ellipsis;

                b{
                    margin-right: 6px;
                    color: #111;
                    font-style: italic;
                }

                em{
                    overflow: hidden;
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    transform: translateY(-50%);

                    &.up{
                        @include sprite-retina($sp_total_search_up);
                        margin-right: 6px;
                    }

                    &.down{
                        @include sprite-retina($sp_total_search_down);
                        margin-right: 6px;
                    }

                    &.same{
                        width: 9px;
                        height: 2px;
                        margin-right: 6.5px;
                        background: #666;
                    }

                    &.new{
                        padding: 0 1px;
                        color: #f43142;
                        font-size: 10px;
                        font-weight: bold;
                    }
                }
            }

            &:nth-child(-n+3){
                a{
                    color: #f43142;

                    b{
                        color: #f43142;
                    }
                }
            }
        }

        .time{
            padding: 0 16px;
            color: #999;
            font-size: 11px;
            letter-spacing: 0;
        }
    }

    &_none{
        padding: 135px 0;
        color: #999;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
    }

    //swiper관련 css
    &_keyword{
        .swiper-container-no-flexbox .swiper-slide {
            float: left;
        }
        .swiper-container-vertical > .swiper-wrapper {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }
        .swiper-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-transition-property: -webkit-transform;
            transition-property: -webkit-transform;
            -o-transition-property: transform;
            transition-property: transform;
            transition-property: transform, -webkit-transform;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
        }
        .swiper-container-android .swiper-slide,
        .swiper-wrapper {
            -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0);
        }
        .swiper-slide {
            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            position: relative;
            -webkit-transition-property: -webkit-transform;
            transition-property: -webkit-transform;
            -o-transition-property: transform;
            transition-property: transform;
            transition-property: transform, -webkit-transform;
        }
        .swiper-slide-invisible-blank {
            visibility: hidden;
        }
        /* Auto Height */
        .swiper-container-autoheight,
        .swiper-container-autoheight .swiper-slide {
            height: auto;
        }
        .swiper-container-autoheight .swiper-wrapper {
            -webkit-box-align: start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
            -webkit-transition-property: height, -webkit-transform;
            transition-property: height, -webkit-transform;
            -o-transition-property: transform, height;
            transition-property: transform, height;
            transition-property: transform, height, -webkit-transform;
        }
        /* IE10 Windows Phone 8 Fixes */
        .swiper-container-wp8-horizontal,
        .swiper-container-wp8-horizontal > .swiper-wrapper {
            -ms-touch-action: pan-y;
            touch-action: pan-y;
        }
        .swiper-container-wp8-vertical,
        .swiper-container-wp8-vertical > .swiper-wrapper {
            -ms-touch-action: pan-x;
            touch-action: pan-x;
        }
    }
}

body.totalSearchType {
    background: #eee;

    > *:not(#wrap) {
        display: none !important;
    }

    #wrap > *:not(header) {
        display: none !important;
    }

    .head_bnr {
        display: none !important;
    }
}
