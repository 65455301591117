body.backType {
    position: relative;
    height: auto;
    min-height: 100%;
}

#back {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 101;
}

/* 투명배경타입 */
#back.type1 {
    background: rgba(255, 255, 255, .01);
}
body.backType_fix {
    position: relative;
    height: 100%;

    #wrap {
        overflow: hidden;
        position: relative;
        height: 100%;
    }
}

.dim {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    @include dimned-opacity();
}

/* mwUI.sticky에 필요한 style - .mwUI_is_fixed : sticky가 지원되지 않을 경우 class; .mwUI_is_sticky : sticky가 지원되는 경우 class; */
.mwUI_is_fixed {
    position: fixed;
    top: 0;
    z-index: 100;
    -moz-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.mwUI_is_sticky {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
}
.showLayer {
    -webkit-transform: translate(-300px, 0);
}
