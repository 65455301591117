/* checkbox & radio */
.chkBox {
    display: inline-block;

    input[type="checkbox"] {
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
    }

    label {
        display: inline-block;
        min-height: 24px;

        &:before {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 4px;
            background: url("/MW/img/order/img_form.png") no-repeat 0 -31px;
            background-size: 31px 131px;
            vertical-align: middle;
        }
    }

    input[type="checkbox"]:checked + label:before {
        background-position: 0 -56px;
    }
}

.rdoBox {
    display: inline-block;

    input[type="radio"] {
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
    }

    label {
        display: inline-block;
        min-height: 24px;

        &:before {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 4px;
            background: url("/MW/img/order/img_form.png") no-repeat 0 -81px;
            background-size: 31px 131px;
            vertical-align: middle;
        }
    }

    input[type="radio"]:checked + label:before {
        background-position: 0 -106px;
    }
}

// checkbox & radio - New Design
.check_box {
    display: inline-block;

    input[type="checkbox"] {
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
    }

    label {
        display: inline-block;
        position: relative;
        padding-left: 32px;
        min-height: 24px;
        line-height: 24px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px;
            background: #fff;
            border: 1px solid #ccc;
            vertical-align: middle;
            box-sizing: border-box;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 10px;
            width: 5px;
            transform: translate(9px, 5px) rotate(45deg);
            border: solid #ddd;
            border-width: 0 1px 1px 0;
        }
    }

    input[type="checkbox"]:checked + label:before {
        border-color: $color-11st-red;
        background: $color-11st-red;
    }

    input[type="checkbox"]:checked + label:after {
        border-color: #fff;
    }

    input[type="checkbox"]:disabled + label {
        color: #ccc;

        &:before {
            border-color: #eee;
            background: #eee;
        }

        &:after {
            border-color: #fff;
        }
    }
}

.check_box_toggle {
    display: inline-block;

    input[type="checkbox"] {
        position: absolute;
        width: 0;
        height: 0;
        margin: 0;
        opacity: 0;
    }

    label {
        display: inline-block;
        position: relative;
        padding-left: 56px;
        min-height: 24px;
        line-height: 24px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 48px;
            height: 24px;
            background: #bbb;
            border-radius: 24px;
            vertical-align: middle;
            transition: 0.3s ease-in;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 22px;
            width: 22px;
            margin: 1px 25px 1px 1px;
            background: #fff;
            border-radius: 50%;
            transition: 0.3s ease-in;
        }

        &:active:after {
            transform: scale(1.15, 0.85);
        }
    }

    input[type="checkbox"]:checked + label:before {
        background: $color-11st-red;
    }

    input[type="checkbox"]:checked + label:after {
        transform: translateX(24px);
    }

    input[type="checkbox"]:disabled + label {
        color: #ccc;

        &:before {
            background: #f4f4f4;
        }
    }
}

.radio_box {
    display: inline-block;

    input[type="radio"] {
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
    }

    label {
        display: inline-block;
        min-height: 24px;
        line-height: 24px;

        &:before {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 4px;
            border: 1px solid #ccc;
            border-radius: 100%;
            background-color: #fff;
            background-image: radial-gradient(closest-side, #ddd 47%, #fff 52%);
            vertical-align: middle;
            box-sizing: border-box;
        }
    }

    input[type="radio"]:checked + label:before {
        border-color: $color-11st-red;
        background-image: radial-gradient(closest-side, #fff 47%, $color-11st-red 52%);
    }

    input[type="radio"]:disabled + label {
        color: #ccc;

        &:before {
            background-image: radial-gradient(closest-side, #fff 47%, #eee 52%);
            border-color: #eee;
        }
    }
}

select.select {
    height: 32px;
    padding: 0 32px 0 3px;
    border: 1px solid #ccc;
    background-color: #fff;
    background-image: url("/MW/img/skpui/ico_dropdown.png");
    background-size: 31px 30px;
    background-position: 100% 50%;
    background-repeat: no-repeat;
    color: #333;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
