.c-gnb-mw--sub {
    & + .c-gnb-redface__search {
        display: none;
    }
}

.c-gnb-mw.c-gnb-mw--listing {
    & + .c-gnb-redface__search {
        padding: 4px 53px 4px 116px;
        margin-top: -52px;
        background-color: $color-11st-red;

        .c-gnb-redface__search-box {
            height: 44px;
            padding-left: 0;

            input[type="search"] {
                height: 44px;
                color: #111;
                border: 1px solid #cf1525;
                font-size: 17px;
                font-weight: bold;
                padding-right: 34px;
            }
            input[type="submit"] {
                display: none;
            }
            button[type="button"] {
                top: 9px;
                right: 6px;
            }
        }
    }
    .totalSearchType & + {
        .c-gnb-redface__search {
            padding-left: 4px;
            padding-right: 4px;

            input[type="submit"] {
                display: block;
            }
        }
    }
}
