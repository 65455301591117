/* wait */
.pop_wait {
    padding-bottom: 15px;
    border: 2px solid #000;
    background: #fff;
    z-index: 999;

    h1 {
        height: 37px;
        border-bottom: 1px solid #d3d3d3;
        background: #ebebeb;
        color: #4a5566;
        font-size: 1em;
        line-height: 37px;
        text-align: center;
    }

    .time {
        width: 250px;
        height: 170px;
        margin: 18px auto 0;
        background: url("/MW/img/common/wait.png") no-repeat 50% 0;
        text-align: center;

        em {
            display: block;
            padding: 55px 0 0 0;
            color: #4a5566;
            font-size: .85em;
            font-weight: bold;
            line-height: 1.3em;
        }

        span {
            display: block;
            margin-top: 7px;
            color: #e01c22;
            font-size: 1.5em;
            font-weight: bold;
            line-height: 1.3em;
        }

        button {
            overflow: hidden;
            position: relative;
            width: 35px;
            height: 35px;
            margin-top: 5px;
            border: none;
            background: none;
            text-indent: -1000px;

            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                top: 9px;
                width: 5px;
                height: 17px;
                background: #4a5566;
                border-radius: 3px;
            }

            &:before {
                left: 11px;
            }

            &:after {
                left: 20px;
            }
        }
    }

    .tm {
        margin-top: 10px;
        color: #464646;
        font-size: .8em;
        font-weight: bold;
        line-height: 1.3em;
        text-align: center;

        span {
            color: #1793e6;
            text-decoration: underline;
            letter-spacing: 0;
        }
    }

    .ts {
        margin-top: 10px;
        color: #797979;
        font-size: .75em;
        font-weight: bold;
        line-height: 1.3em;
        text-align: center;
    }
}
