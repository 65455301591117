/* Loading */
.loading_full {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 102;

    .circle {
        overflow: hidden;
        width: 12px;
        height: 12px;
        margin: -11px 0 0 -11px;
        border: 4px dotted rgba(255, 255, 255, .7);
        border-radius: 50%;
        color: transparent;
        -webkit-animation-name: myAnimationLoaingFull;
        -webkit-animation-duration: .65s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-name: myAnimationLoaingFull;
        animation-duration: .65s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        &.type1 {
            border-color: rgba(0, 0, 0, .7);
        }
    }

    .txt {
        width: 300px;
        margin: 65px 0 0 -150px;
        color: #fff;
        font-size: 1em;
        text-align: center;
    }
}

@-webkit-keyframes myAnimationLoaingFull {
    from {
        -webkit-transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes myAnimationLoaingFull {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}
