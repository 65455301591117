/* common-btn */
// 이전 버튼 이후 정리하여 삭제필요
.btns,
.btnsr,
.btnsrw,
.btnsg,
.btnsb {
    display: inline-block;
    padding: 1px;
    background: #fff;
    border-radius: 4px;
}

.btns > *,
.btnsr > *,
.btnsrw > *,
.btnsg > *,
.btnsb > * {
    display: block;
    height: 21px;
    padding: 0 8px;
    border: none;
    color: #666;
    font-size: 12px;
    font-weight: bold;
    line-height: 21px;
    text-align: center;
}

.btns {
    border: 1px solid #bfbfbf;

    & span,
    & input,
    & button {
        background: #fff;
    }

    & span.arr {
        padding-right: 18px;
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin: 5px 0 0 5px;
            border-left: 5px solid #8d8d8d;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
        }
    }

    & span.back {
        padding-left: 18px;

        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin: 5px 0 0 -10px;
            border-right: 5px solid #8d8d8d;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
        }
    }
}

.btnsr {
    border: 1px solid $color-11st-red;
    background: $color-11st-red;

    & > * {
        color: #fff;
        background: $color-11st-red;
    }
}

.btnsrw {
    border: 1px solid $color-11st-red;

    & > * {
        color: $color-11st-red;
        background: #fff;
    }
}

.btnsg {
    border: 1px solid #8f8f8f;
    background: #8f8f8f;

    & > * {
        color: #fff;
        background: #8f8f8f;
    }
}

.btnsb {
    border: 1px solid $color-11st-blue;
    background: $color-11st-blue;

    & > * {
        color: #fff;
        background: $color-11st-blue;
    }
}

.btnb,
.btnbr,
.btnp,
.btnpr,
.btnbb,
.btnbbb {
    display: inline-block;
    padding: 1px;
    border: 1px solid #8c8c8c;
    border-radius: 4px;
    background: #fff;
    text-align: center;
}

.btnb > *,
.btnbr > *,
.btnp > *,
.btnpr > *,
.btnbb > *,
.btnbbb > * {
    display: block;
    height: 35px;
    padding: 0 8px;
    margin: 0;
    border: none;
    color: #555;
    font-size: 15px;
    font-weight: bold;
    line-height: 35px;
    background: #fff;
}

.btnb {
    border: 1px solid #8c8c8c;

    & > * {
        color: #555;
        background: #fff;
    }
}

.btnbr {
    border: 1px solid $color-11st-red;

    & > * {
        color: $color-11st-red;
        background: #fff;
    }
}

.btnp {
    border: 1px solid #595959;

    & > * {
        color: #111;
        background: #fff;
    }
}

.btnpr {
    border: 1px solid $color-11st-red;
    background: $color-11st-red;

    & > * {
        color: #fff;
        background: $color-11st-red;
    }
}

.btnbbb {
    border: 1px solid $color-11st-blue;
    background: $color-11st-blue;

    & > * {
        color: #fff;
        background: $color-11st-blue;
    }
}

.btnbb {
    border: 1px solid $color-11st-blue;

    & > * {
        color: $color-11st-blue;
        background: #ffffff;
    }
}

.btn_w2 {
    overflow: hidden;
    margin: 8px 4px 0;
    text-align: center;

    div {
        float: left;
        width: 50%;

        & > span,
        & a {
            display: block;
            margin: 0 4px;
        }

        & a.btnb span {
            padding-left: 0;
            padding-right: 0;
        }

        & button,
        & input {
            width: 100%;
            padding: 0;
        }
    }
}

.btn_wL {
    padding-top: 8px;
    margin: 8px 8px 0;
    border-top: 1px solid #676767;
}

.btn_w2.btn_wL div:first-child a {
    margin-left: 0;
}

.btn_w2.btn_wL div:first-child > span {
    margin-left: 0;
}

.btn_w2.btn_wL div:last-child a,
.btn_w2.btn_wL div:last-child > span {
    margin-right: 0;
}

/* common-btn - New */
%btn-common-style {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    //background-image: linear-gradient(rgba(17, 17, 17, 0), rgba(17, 17, 17, 0.04));
    color: #111;
    //border-radius: 2px;
    box-sizing: border-box;
    letter-spacing: -1px;

    &.rd {
        border-color: $color-11st-red;
        background: $color-11st-red;
        color: #fff;
    }

    &.gb {
        border-color: #868ba8;
        background: #868ba8;
        color: #fff;
    }

    &.frd {
        border-color: $color-11st-red;
        color: $color-11st-red;
    }

    &.fbl {
        border-color: $color-11st-blue;
        color: $color-11st-blue;
    }

    &.bk {
        border-color: #444;
        background: #444;
        color: #fff;
    }

    &.gy {
        border-color: #757575;
        background: #757575;
        color: #fff;
    }

    &:disabled {
        border-color: rgba(204, 204, 204, 0.4);
        background-color: #f4f4f4;
        color: #ccc;
    }
}

.bbtn {
    @extend %btn-common-style;
    padding: 0 14px;
    font-size: 16px;
    line-height: 46px;

    &--bold {
        font-weight: 600;
    }
}

.mbtn {
    @extend %btn-common-style;
    padding: 0 14px;
    font-size: 13px;
    line-height: 30px;
}

.sbtn {
    @extend %btn-common-style;
    padding: 0 8px;
    font-size: 12px;
    line-height: 22px;
}

input[type="button"].bbtn,
input[type="submit"].bbtn,
input[type="reset"].bbtn,
input[type="button"].mbtn,
input[type="submit"].mbtn,
input[type="reset"].mbtn {
    border-radius: 2px;
}

.bbtn.bno {
    padding-top: 1px;
    padding-bottom: 1px;
    border: none;
}

.bbtn.mor:after {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-left: 2px;
    border: 1px solid #666;
    border-width: 2px 2px 0 0;
    transform: rotate(45deg);
    content: "";
}

.bbtn.gb.mor:after {
    border-color: #fff;
}

.btn_w {
    margin: 8px 8px 0;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;

    & > * {
        display: block;
        width: 100%;
        margin: 0 4px;
        padding: 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1;
    }
    & > *:first-child {
        margin-left: 0;
    }
    & > *:last-child {
        margin-right: 0;
    }
    // Modify prev UI
    a,
    button,
    input {
        display: block;
        width: 100%;
    }
}

.btn_wc {
    margin-top: 8px;
    text-align: center;
}

.btn_wc > * {
    margin: 0 2px;
}
